import {BasicObject, NavigatorScreenProps} from './types';
import Home from './pages/home';
import Casino from './pages/casino';
import Invitation from './common-pages/invitation';
import Sports from './common-pages/sports';
import Me from './pages/me';

export const mainPageList: {
  name: string;
  link: string;
  component: (props: NavigatorScreenProps) => React.JSX.Element;
  img?: string;
  activeImg?: string;
  unmountOnBlur?: boolean;
  params?: BasicObject;
  middle?: boolean;
  svgIcon?: ({active}: {active: boolean}) => React.ReactNode;
}[] = [
  {
    name: 'Home',
    link: 'index/home',
    component: Home,
    img: require('@assets/icons/main-tab.07/home.webp'),
    activeImg: require('@assets/icons/main-tab.07/home-selected.webp'),
  },
  {
    name: 'Casino',
    link: 'index/casino',
    component: Casino,
    img: require('@assets/icons/main-tab.07/casino.webp'),
    activeImg: require('@assets/icons/main-tab.07/casino-selected.webp'),
  },
  {
    name: 'Invite',
    link: 'index/referral',
    component: Invitation,
    middle: true,
    img: require('@assets/icons/main-tab.07/invite.webp'),
  },
  {
    name: 'Sports',
    link: 'index/sports',
    component: Sports,
    img: require('@assets/icons/main-tab.07/sports.webp'),
    activeImg: require('@assets/icons/main-tab.07/sports-selected.webp'),
    params: {
      hideBack: true,
    },
  },
  {
    name: 'Me',
    link: 'index/me',
    component: Me,
    img: require('@assets/icons/main-tab.07/me.webp'),
    activeImg: require('@assets/icons/main-tab.07/me-selected.webp'),
  },
];
