import {Header} from '@/components/v2/business/category/category';
import React, {FC, useCallback, useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {ReactComponent as WinInfoSvg} from '../svgs/wininfo-header-wrap.svg';
import {Image} from 'antd-mobile';
import styles from './winning-information.module.scss';
import {toPriceStr} from '@/utils';
import {motion, useAnimation} from 'framer-motion';
import {WinnerItem} from '../home.type';

interface WinningInformationItemProps {
  avatar?: string;
  rank: number;
  userName: string;
  gamePic?: string;
  amount: number;
}

const WinningInformationItem: FC<WinningInformationItemProps> = ({
  avatar,
  rank,
  userName,
  gamePic,
  amount,
}) => {
  const {t} = useTranslation();
  return (
    <div className={`${styles['win-item']} relative`}>
      <div
        className={
          'absolute top-1 left-1 flex items-center justify-center w-11 h-11'
        }>
        <WinInfoSvg className="absolute top-0 left-0 w-11 h-11 text-c1" />
        <Image
          src={avatar}
          width="2.375rem"
          height="2.375rem"
          className="rounded-full"
          lazy
        />
        <div className="absolute bottom-0 right-0 rounded-full size-[1.125rem] bg-c1 text-t1 text-[.625rem] flex items-center justify-center">
          V{rank}
        </div>
      </div>
      <span className="absolute top-[1.15625rem] left-[4.266875rem] max-w-[5rem] text-t1 text-xs break-all">
        {userName}
      </span>
      <div className="absolute top-0 right-[.604375rem] h-full flex flex-row items-center gap-6">
        <Image
          src={gamePic}
          width="3.25rem"
          height="3.25rem"
          className="rounded-lg"
          lazy
        />
        <div className="flex flex-col items-end">
          <span className="text-t1 text-base font-bold">
            {toPriceStr(amount)}
          </span>
          <span className="text-t2 text-xs">
            {t('home.label.winningAmount')}
          </span>
        </div>
      </div>
    </div>
  );
};

interface WinningInformationProps {
  list: WinnerItem[];
}

const WinningInformation: FC<WinningInformationProps> = ({list}) => {
  const {t} = useTranslation();

  const outerRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);

  const control = useAnimation();

  const startAnimated = useCallback(() => {
    const outer = outerRef.current;
    const inner = innerRef.current;
    if (control) {
      control.stop();
      control.set({y: 0});
    }
    if (outer && inner) {
      const scrollHeight = inner.clientHeight - outer.clientHeight;
      control.start({
        y: [0, -scrollHeight],
        transition: {
          duration: scrollHeight / 20,
          repeat: Infinity,
          repeatDelay: 5,
        },
      });
    }
  }, [control]);

  useEffect(() => {
    if (list && list?.length >= 3) {
      startAnimated();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [list]);

  return (
    <>
      {!!list && !!list.length && (
        <div className={'flex flex-col gap-3'}>
          <Header
            icon={require('@assets/imgs/home.07/winning-information.webp')}
            title={t('home.title.winningInformation')}
          />
          <div
            ref={outerRef}
            className={'flex flex-col px-3 max-h-[14.875rem] overflow-hidden'}>
            <motion.div
              ref={innerRef}
              animate={control}
              className="flex flex-col gap-2">
              {list?.map((item, index) => (
                <WinningInformationItem
                  key={index}
                  avatar={item.userAvatar}
                  rank={item.userLevel != null ? item.userLevel : 0}
                  userName={item.userName}
                  gamePic={item.gamePic}
                  amount={item.bonus}
                />
              ))}
            </motion.div>
          </div>
        </div>
      )}
    </>
  );
};

export default WinningInformation;
