import Category from '@/components/v2/business/category/category';
import React, {FC, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {Image} from 'antd-mobile';
import {WinnerItem} from '../home.type';
import {toPriceStr} from '@/utils';

interface SuperJackpotProps {
  list?: WinnerItem[];
}

const SuperJackpot: FC<SuperJackpotProps> = ({list = []}) => {
  const {t} = useTranslation();

  const filteredList = useMemo(() => {
    return list.slice(0, 9);
  }, [list]);

  return (
    <Category
      icon={require('@assets/imgs/home.07/super-jackpot.webp')}
      col={3}
      title={t('category.label.superJackpot')}
      // headerDesc={
      //   <div className="text-t3 text-[.625rem]">{t('home.tip.original')}</div>
      // }
      list={filteredList}
      renderItem={item => {
        return (
          <div
            className="flex flex-col text-t1 bg-card border-b-[.0625rem] border-b-c1 rounded-lg"
            key={item.gamesId}>
            <div className="flex flex-col items-center justify-center h-10">
              <div className="flex flex-row items-center gap-1">
                <Image
                  src={item.userAvatar}
                  width="1rem"
                  height="1rem"
                  className="border-1 border-c2 rounded-2xl"
                />
                <span className="text-[.625rem]">
                  {item.userPhone || item.userName}
                </span>
              </div>
              <span className="font-bold text-[var(--f1)] text-xs">
                {item.odds || '0'}
                {'X'}
              </span>
            </div>

            <Image src={item.gamePic} width="100%" height="5.25rem" />
            <div className="flex flex-col items-center justify-center h-10">
              <span className="text-[.625rem]">{t('label.bonus')}</span>
              <span className="font-bold text-[var(--f1)] text-xs">
                {toPriceStr(item.bonus)}
              </span>
            </div>
          </div>
        );
      }}
    />
  );
};

export default SuperJackpot;
