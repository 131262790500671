import Category from '@/components/v2/business/category/category';
import {HomeGameCardItem, HomeGameFloorItem} from '../home.type';
import React, {FC, useCallback, useEffect, useRef, useState} from 'react';
import CasinoGamesCard from '@/components/v2/business/category/casino-games-card';
import styles from './hot.module.scss';
import {Image} from 'antd-mobile';
import {useRequest} from 'ahooks';

import {getBetBy, getGameURL, getHomeGames} from '../home.service';
import {debounce, envConfig, getUrlParams, goTo} from '@/utils';
import {toAllCasinoGame} from '@/common-pages/game-navigate';
import NoData from '@/components/basic/error-pages/no-data';
import Spin from '@/components/v2/basic/spin';
import globalStore from '@/services/global.state';
import {useFocusEffect} from '@react-navigation/native';
import {useLogin} from '@/utils/state-hooks';
// import {homePage} from '@/config';

export function renderCategory(
  category: HomeGameFloorItem,
  onTab?: (tabkey: string) => void,
) {
  const getSportURL = debounce((gametype: string) => {
    globalStore.globalLoading.next(true);
    getGameURL(gametype)
      .then((url: string) => {
        globalStore.openSports.next({
          type: gametype,
          name: gametype.split('_')[0].toUpperCase() + ' Sports',
          url,
        });
      })
      .catch(e => {
        const {log} = console;
        log('error', e);
      })
      .finally(() => globalStore.globalLoading.next(false));
  });
  const jumpGame = (v: HomeGameCardItem) => {
    if (
      v.name.toLowerCase() === 'dice game' ||
      v.gameType.toLowerCase() === 'dice'
    ) {
      const params = getUrlParams(v.gameUrl);
      params.cycle = 1;
      goTo('Dice', params);
    } else if (
      v.name.toLowerCase() === 'wingo' ||
      v.gameType.toLowerCase() === 'color'
    ) {
      goTo('Color', {...getUrlParams(v.otherParams), name: v.name});
    } else if (v.gameType.toLowerCase() === 'quick 3d') {
      goTo('Digit', {
        pickGameType: 'quick',
        // cycle: item.cycle,
      });
    } else if (v.name.toLowerCase() === 'state lottery') {
      goTo('Lottery');
    }
  };
  const renderItem = (item: HomeGameCardItem) => {
    switch (category.uniqueKey) {
      case 'hotGames':
      case 'ClassicGames':
      case 'liveCasinoV2':
      case 'casinoV2':
        return (
          <CasinoGamesCard
            onClick={() => toAllCasinoGame(item.id)}
            width="7rem"
            height="9.25rem"
            key={item.id}
            pic={item.gamePic}
            title={item.name}
            // odds={item.odds}
            desc={item.provider}
            onlyPic
          />
        );
      case 'Slots':
      case 'Provider':
        return (
          <div
            key={item.id}
            className="px-3.5 py-2 flex items-center justify-center bg-card rounded"
            onClick={() => goTo('Providers', {provider: item.provider})}>
            <Image src={item.gamePic} height="1.5rem" />
          </div>
        );
      case 'original':
        return (
          <CasinoGamesCard
            onClick={() => toAllCasinoGame(item.id)}
            width="7rem"
            height="9.25rem"
            key={item.id}
            hasDesc={false}
            title={item.name}
            pic={item.gamePic}
            onlyPic
          />
        );
      case 'lotteryV2':
        return (
          <CasinoGamesCard
            onClick={() => jumpGame(item)}
            height="9.25rem"
            key={item.id}
            hasDesc={false}
            title={item.name}
            pic={item.gamePic}
            onlyPic
          />
        );
      case 'fishing':
        return (
          <CasinoGamesCard
            onClick={() => toAllCasinoGame(item.id)}
            width="7rem"
            height="9.25rem"
            key={item.id}
            hasDesc={false}
            title={item.name}
            pic={item.gamePic}
            onlyPic
          />
        );
      case 'sports':
        return (
          <div
            key={item.id}
            className="relative h-[6rem] flex flex-row items-center justify-end rounded-lg overflow-hidden"
            onClick={() => getSportURL(item.gameUrl)}>
            <Image
              src={item.gamePic}
              width={'auto'}
              height={'6rem'}
              className="absolute top-0 left-0"
            />
          </div>
        );
      default:
        return <div key={item.id} />;
    }
  };
  const itemSpan = (item: HomeGameCardItem) => {
    return item.picPlaceholder || 1;
  };
  const tabkeyMap: Record<string, string> = {
    ClassicGames: 'Classic',
    original: 'Original',
    liveCasinoV2: 'Live Casino',
    casinoV2: 'Casino',
    lotteryV2: 'Lottery',
    fishing: 'Fishing',
  };
  let row = 1,
    col = 1,
    sliderSize = (119 / 351) * 100,
    className,
    desc,
    footer,
    contentPaddingPosition: 'content' | 'slider' = 'slider',
    onAll =
      category.uniqueKey === 'hotGames'
        ? () => goTo('Casino')
        : () =>
            tabkeyMap[category.uniqueKey]
              ? onTab?.(tabkeyMap[category.uniqueKey])
              : goTo('Casino');
  switch (category.uniqueKey) {
    case 'Slots':
    case 'Provider':
      row = 3;
      col = 3;
      className = 'slots-category';
      sliderSize = 100;
      onAll = () => goTo('Providers');
      contentPaddingPosition = 'content';
      break;
    case 'sports':
      col = category.gamesList?.length && category.gamesList.length > 1 ? 2 : 1;
      contentPaddingPosition = 'content';
      break;
    case 'lotteryV2':
      col = 3;
      contentPaddingPosition = 'content';
      className = 'slots-category';
    // case 'original':
    //   desc = (
    //     <div className="text-t3 text-[.625rem]">
    //       {i18n.t('home.tip.original')}
    //     </div>
    //   );
    //   break;
    // case 'lotteryV2':
    //   footer = (
    //     <div className="mx-3 px-6 py-4 flex flex-row gap-2 bg-image rounded-xl">
    //       <Image width="3rem" height="3rem" />
    //       <div className="w-[8.6875rem] flex flex-col text-t1">
    //         <span className="text-xs font-bold">
    //           {i18n.t('category.label.lotteryTitle')}
    //         </span>
    //         <span className="text-[.625rem]">
    //           {i18n.t('category.label.lotteryContent')}
    //         </span>
    //       </div>
    //     </div>
    //   );
    //   break;
  }

  let gameList: HomeGameCardItem[] = category.gamesList || [];

  return gameList?.length ? (
    <Category
      key={category.id}
      icon={category.categoryPic}
      title={category.name}
      list={gameList}
      onAll={onAll}
      total={
        ['sports', 'lotteryV2'].includes(category.uniqueKey)
          ? undefined
          : category.gamesList?.length || 0
      }
      row={row}
      col={col}
      headerDesc={desc}
      className={className ? styles[className] : undefined}
      renderItem={renderItem}
      itemSpan={itemSpan}
      sliderSize={sliderSize}
      footer={footer}
      contentPaddingPosition={contentPaddingPosition}
    />
  ) : null;
}

function renderSports(category: HomeGameFloorItem) {
  return (
    <Category
      key={'betby-widget-sports'}
      icon={category.categoryPic}
      title={category.name}
      list={[{id: 'betby-widget-sports'}]}
      row={1}
      col={1}
      renderItem={item => (
        <div key={item.id} id={item.id} className="w-full relative z-[1]" />
      )}
      contentPaddingPosition="no-padding"
    />
  );
}

interface HotContentProps {
  onTab?: (tabkey: string) => void;
}

const HotContent: FC<HotContentProps> = ({onTab}) => {
  const {data, loading} = useRequest(getHomeGames);
  const debounceTimer = useRef<NodeJS.Timeout>();
  const widgetRef = useRef<any>();
  const [focused, setFocused] = useState(false);
  const login = useLogin();

  const handleInitBetbyWidget = useCallback(() => {
    if (widgetRef.current != null) {
      return;
    }
    const itemdiv = document.getElementById('betby-widget-sports');
    if (!itemdiv) {
      return;
    }
    console.log('itemdiv', itemdiv);
    getBetBy().then(res => {
      widgetRef.current = new BTRenderer().initialize({
        // Brand id
        brand_id: res.brandId,
        // User token
        token: res.token,
        // Language
        lang: 'en',
        // Dom node for widget
        target: itemdiv,
        // Optional theme override,
        // should not be set if you want to use your
        // default brand theme
        themeName: 'default',
        // Widget name:
        widgetName: 'promo',
        // Params specific to widget
        widgetParams: {
          // placeholder name from backoffice
          placeholder: 'operator_page1',
          // callback to change url when user clicks on banner
          onBannerClick: (args: any) => {
            console.log('banner click', args);
            if (!login) {
              goTo('Login');
            }
            goTo('BetBy', {
              'bt-path': args.url,
            });
          },

          withSportsList: true,
          // callback to change url when user clicks on sport icon within sports menu
          onSportClick: (args: any) => {
            console.log('sports click', args);
            if (!login) {
              goTo('Login');
            }
            goTo('BetBy', {
              'bt-path': args.url,
            });
            // goTo('BetBy', {})
          },
        },
      });
    });
  }, [login]);

  const handleInit = useCallback(() => {
    debounceTimer.current = setTimeout(() => {
      if (!document.getElementById('betbyScript')) {
        const el = document.createElement('script');
        el.src = envConfig.betbyScriptUrl;
        el.id = 'betbyScript';
        document.body.insertBefore(el, null);
        el.onload = handleInitBetbyWidget;
      } else {
        handleInitBetbyWidget();
      }
    }, 500);
  }, [handleInitBetbyWidget]);

  useFocusEffect(
    useCallback(() => {
      setFocused(true);
    }, []),
  );

  useEffect(() => {
    if (focused && data?.find(item => item.uniqueKey === 'sports')) {
      handleInit();
    }

    return () => {
      const current = widgetRef.current;
      if (current != null) {
        current.kill();
        widgetRef.current = null;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [focused, data, login]);
  // const rSports = useMemo(() => {
  //   return renderSports({
  //     id: 1,
  //     name: 'Sports',
  //     categoryPic: 'https://cdn.betby.com/images/sports.png',
  //     uniqueKey: 'sports',
  //     gamesList: [],
  //   });
  // }, []);
  return (
    <Spin loading={loading}>
      {!loading && !!data && !!data.length ? (
        data.map(item =>
          item.uniqueKey === 'sports'
            ? renderSports(item)
            : renderCategory(item, onTab),
        )
      ) : (
        <div className="h-[18rem]">
          <NoData />
        </div>
      )}
    </Spin>
  );
};

export default HotContent;
