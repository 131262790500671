import AsyncStorage from '@react-native-async-storage/async-storage';
import {BehaviorSubject, Subject} from 'rxjs';
import {BasicObject} from '@types';
import {Platform} from 'react-native';
import type {MessageCountInfo} from './global.service';
import {ReactElement} from 'react';
import {ToastOptionsType} from '@/components/basic/modal';
import {CurrencyMap} from './currency';

export interface SportsInfo {
  name: string;
  url: string;
  type: string;
}
class GlobalStore {
  globalLoading = new BehaviorSubject<boolean>(false);
  globalTotal = new Subject<{
    type: ToastOptionsType;
    message: string;
    tip?: string | ReactElement;
  }>();
  globalSucessTotal = (message: string, tip?: string | ReactElement) => {
    this.globalTotal.next({
      type: 'success',
      message,
      tip,
    });
  };
  globalWaringTotal = (message: string, tip?: string | ReactElement) => {
    this.globalTotal.next({
      type: 'warning',
      message,
      tip,
    });
  };
  doNotices = new Subject<void>();
  openSports = new Subject<SportsInfo | undefined>();

  /** 浏览器 */
  isWeb = Platform.OS === 'web';
  /** 移动端浏览器 */
  isH5 =
    Platform.OS === 'web' &&
    !!navigator.userAgent.match(
      /(iPhone|iPod|Android|ios|iOS|iPad|WebOS|Symbian|Windows Phone|Phone)/i,
    );
  /** android */
  isAndroid = Platform.OS === 'android';
  private _channel: string = null!;
  /** 渠道,会在后续的版本中删除,固定从缓存读取
   * @deprecated
   */
  get channel() {
    return this._channel;
  }
  set channel(c: string) {
    this._channel = c;
    this.asyncSetItem('channel', c);
  }
  isIOS = Platform.OS === 'ios';
  asyncGetItem = (key: string) => {
    return AsyncStorage.getItem(key);
  };
  asyncRemoveItem = (key: string) => {
    AsyncStorage.removeItem(key);
  };
  asyncSetItem = (key: string, value: string) => {
    AsyncStorage.setItem(key, value);
  };
  private _token: string | null = null!;
  get token() {
    return this._token;
  }
  set token(token: string | null) {
    if (token) {
      this._token = token;
      this.tokenSubject.next(token);
      this.asyncSetItem('token', token);
    } else {
      this.clearToken();
    }
  }
  clearToken = () => {
    this._token = null;
    this.asyncRemoveItem('token');
    this.tokenSubject.next(null);
  };
  tokenSubject = new BehaviorSubject<string | null>(this._token);

  /** 用户信息 */
  private _userInfo: BasicObject | null = null;
  get userInfo() {
    return this._userInfo;
  }

  set userInfo(user: BasicObject | null) {
    if (user) {
      this._userInfo = user;
      this.userSubject.next(user);
      this.asyncSetItem('user', JSON.stringify(user));
    } else {
      this._userInfo = null;
      this.asyncRemoveItem('user');
    }
  }
  userSubject = new BehaviorSubject<BasicObject | null>(this._userInfo);
  betbyBackSubject = new Subject<string>();

  /** IM ICON */
  private _imIcon: string | undefined = undefined;
  get imIcon() {
    return this._imIcon;
  }
  set imIcon(pic: string | undefined) {
    this._imIcon = pic;
  }

  private _visitor: string | null = null!;
  /** 唯一标识 */
  get visitor() {
    return this._visitor;
  }
  set visitor(visitor: string | null) {
    this._visitor = visitor;
    if (visitor) {
      this.asyncSetItem('visitor', visitor);
    } else {
      this.asyncRemoveItem('visitor');
    }
  }

  private _lang: string = 'en_US';
  /** 语言 */
  get lang() {
    return this._lang;
  }
  set lang(lang: string) {
    this._lang = lang;
    if (lang) {
      this.asyncSetItem('language', lang);
    } else {
      this.asyncRemoveItem('language');
    }
  }
  currency: string = CurrencyMap.default;
  private _userAmount: number = 0;
  private _userLastAmount: number | null = null;
  /** 用户当前金额 */
  get userAmount() {
    return this._userAmount;
  }

  /** 金额带出 app.tsx中处理,其他地方应该避免订阅 */
  amountCheckOut = new Subject<{gameType?: number}>();
  /** 金额更新 app.tsx中处理,其他地方应该避免订阅 */
  updateAmount = new Subject<{gameType?: string}>();
  private _amountChanged = new BehaviorSubject<{
    last: number | null;
    current: number;
  }>({
    last: null,
    current: 0,
  });
  /** app.tsx中统一触发,会有200ms的节流,避免频繁触发 */
  get amountChanged() {
    return this._amountChanged.asObservable();
  }
  /** 通过updateAmount在app.tsx中统一触发,其他地方应该避免订阅 */
  setAmount(amount: number) {
    this._amountChanged.next({
      last: this._userLastAmount,
      current: amount,
    });
    this._userLastAmount = this._userAmount = amount;
  }

  /** 消息数量相关触发 */
  private _notificationSubject = new BehaviorSubject<MessageCountInfo>({
    messageTotalCount: 0,
    sysMessageCount: 0,
    sysUserMessageCount: 0,
  });

  get notificationSubject() {
    return this._notificationSubject;
  }

  /** 消息更新 app.tsx中处理，其他地方避免订阅 */
  refreshNotification = new Subject<void>();

  /** 读取消息
   * @param messageType 1 系统消息 2 用户消息
   */
  readNotification = new Subject<{messageId: number; messageType: 1 | 2}>();

  /** 杂项数据存取 */
  private dataMap: BasicObject = {};
  getItem = (key: string): string | BasicObject | null => {
    if (
      this.dataMap[key] ||
      this.dataMap[key] === 0 ||
      this.dataMap[key] === '' ||
      this.dataMap[key] === null
    ) {
      return this.dataMap[key];
    }
    return null;
  };
  setItem = (
    key: string,
    value: string | number | BasicObject | any[],
    saveToStorage = false,
  ) => {
    if (value || value === 0 || value === '' || value === null) {
      this.dataMap[key] = value;
      if (saveToStorage) {
        const _value =
          typeof value === 'string' ? value : JSON.stringify(value);
        this.asyncSetItem(key, _value);
      }
    }
  };
  removeItem = (key: string) => {
    delete this.dataMap[key];
    this.asyncRemoveItem(key);
  };
}

/** 基础组件中尽量避免依赖GlobalStore */
const globalStore = new GlobalStore();

export default globalStore;
